import React from 'react';
import './ClothingAPILanding.css';
import Frame3 from './Frame_3_6.png';
import Navbar from './Navbar';

const ClothingAPILanding = () => {
  const apiResponseExample = `{
  <span class="key">"product"</span>: {
    <span class="key">"id"</span>: <span class="string">"12345"</span>,
    <span class="key">"name"</span>: <span class="string">"Classic White T-Shirt"</span>,
    <span class="key">"brand"</span>: <span class="string">"ChicBrand"</span>,
    <span class="key">"price"</span>: <span class="number">29.99</span>,
    <span class="key">"currency"</span>: <span class="string">"USD"</span>,
    <span class="key">"sizes"</span>: [<span class="string">"S"</span>, <span class="string">"M"</span>, <span class="string">"L"</span>, <span class="string">"XL"</span>],
    <span class="key">"colors"</span>: [<span class="string">"White"</span>, <span class="string">"Black"</span>, <span class="string">"Gray"</span>],
    <span class="key">"inStock"</span>: <span class="boolean">true</span>,
    <span class="key">"images"</span>: [
      {
        <span class="key">"url"</span>: <span class="string">"https://chicapi.com/images/12345-front.jpg"</span>,
        <span class="key">"type"</span>: <span class="string">"front"</span>,
        <span class="key">"resolution"</span>: <span class="string">"1024x1024"</span>
      },
      {
        <span class="key">"url"</span>: <span class="string">"https://chicapi.com/images/12345-back.jpg"</span>,
        <span class="key">"type"</span>: <span class="string">"back"</span>,
        <span class="key">"resolution"</span>: <span class="string">"1024x1024"</span>
      },
      {
        <span class="key">"url"</span>: <span class="string">"https://chicapi.com/images/12345-detail.jpg"</span>,
        <span class="key">"type"</span>: <span class="string">"detail"</span>,
        <span class="key">"resolution"</span>: <span class="string">"1024x1024"</span>
      }
    ],
    <span class="key">"description"</span>: <span class="string">"A comfortable, versatile white t-shirt..."</span>,
    <span class="key">"material"</span>: <span class="string">"100% Cotton"</span>,
    <span class="key">"category"</span>: <span class="string">"T-Shirts"</span>,
    <span class="key">"tags"</span>: [<span class="string">"casual"</span>, <span class="string">"basics"</span>, <span class="string">"summer"</span>]
  }
}`;

  return (
    <div className="container">
      <Navbar />
      <main className="landing-content">
        <section className="intro-section">
          <h1>Your API for clothing</h1>
          <p>Access a world of fashion data: images, specs, and inventory across brands, styles, and regions</p>
          <div className="use-cases">
            <div className="use-case">
              👚 Power your Virtual Try On studio
            </div>
            <div className="use-case">
              🛒 Enhance your ecommerce platform
            </div>
          </div>
          <div className="cta-buttons">
            <button className="primary-button">Join the waiting list</button>
          </div>
        </section>

        <section className="virtual-try-on-section">
          <div className="virtual-try-on">
            <img src={Frame3} alt="Virtual Try On Tool" />
          </div>
        </section>

        <section className="api-example-section">
          <h3>Example API Response</h3>
          <pre className="code-snippet">
            <code dangerouslySetInnerHTML={{ __html: apiResponseExample }} />
          </pre>
        </section>
      </main>
    </div>
  );
};

export default ClothingAPILanding;