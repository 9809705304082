import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ClothingAPILanding from './ClothingAPILanding';
import BlogPage from './BlogPage';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-ESKV7RDZSK'); // Replace with your Measurement ID
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ClothingAPILanding />} />
        <Route path="/blog/*" element={<BlogPage />} />
      </Routes>
    </Router>
  );
};

export default App;