import React, { useEffect } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import './BlogPage.css';
import Navbar from './Navbar';
import VirtualTryOn from './articles/virtual-try-on';
import article1_pic from './articles/photo_virtualtryon.png';
import article2_pic from './articles/photo_ecommerce.png';
import EcommerceAPI from './articles/ecommerce-api';

const BlogPage = () => {
  useEffect(() => {
    document.title = "Fashion Tech Insights | ChicAPI Blog";
    document.querySelector('meta[name="description"]').setAttribute("content", "Explore the latest trends in fashion technology, from AI-powered virtual try-ons to revolutionary clothing APIs.");
  }, []);

  return (
    <div className="container">
      <Navbar />
      <main className="blog-content">
        <Routes>
          <Route index element={<BlogList />} />
          <Route path="virtual-try-on" element={<VirtualTryOn />} />
          <Route path="ecommerce-api" element={<EcommerceAPI />} />
        </Routes>
      </main>
    </div>
  );
};

const BlogList = () => (
  <>
    <h1 className="blog-title">Fashion Tech Insights</h1>
    <p className="blog-intro">
      Explore the latest trends and innovations in fashion technology. From AI-powered virtual try-ons to revolutionary APIs, discover how tech is reshaping the fashion industry.
    </p>
    <div className="blog-list">
      <Link to="/blog/virtual-try-on" className="blog-post-link">
        <article className="blog-post">
          <img src={article1_pic} alt="Virtual Try On Technology" className="blog-post-image" />
          <div className="blog-post-content">
            <h2>How PhotoAI and Virtual Try-Ons Are Revolutionizing Fashion</h2>
            <p className="blog-post-date">August 23, 2024</p>
            <p className="blog-post-extract">
              Discover how AI-driven virtual try-on technology is transforming online shopping experiences. Learn about the potential of PhotoAI and how clothing APIs are powering the next generation of fashion tech.
            </p>
            <Link to="/blog/virtual-try-on" className="read-more-link">
              Read more →
            </Link>
          </div>
        </article>
      </Link>
      <Link to="/blog/ecommerce-api" className="blog-post-link">
        <article className="blog-post">
          <img src={article2_pic} alt="E-commerce API" className="blog-post-image" />
          <div className="blog-post-content">
            <h2>Revolutionizing E-commerce with Clothing APIs</h2>
            <p className="blog-post-date">January 22, 2024</p>
            <p className="blog-post-extract">
              Explore how clothing APIs are transforming the e-commerce landscape. Learn about the benefits of integrating these powerful tools into your online retail strategy and how they're shaping the future of fashion tech.
            </p>
            <Link to="/blog/ecommerce-api" className="read-more-link">
              Read more →
            </Link>
          </div>
        </article>
      </Link>
    </div>
  </>
);

export default BlogPage;