import React from 'react';
import { Link } from 'react-router-dom';
import LogoSrc from './clothingAPI_logo_clean2.svg';
import './Navbar.css';

const Navbar = () => {
  return (
    <header className="navbar">
      <Link to="/" className="logo-link">
        <div className="logo">
          <img src={LogoSrc} alt="ChicAPI Logo" />
          <span>ChicAPI</span>
        </div>
      </Link>
      <nav className="main-nav">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;